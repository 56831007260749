
import { Component, Prop, Vue } from 'vue-property-decorator';
import ApiService from '../services/api';

import { ConceptModel } from '@/models';
import { toLocale } from '@/helpers/date';

import RestaurantStatus from '@/components/buttons/RestaurantStatus.vue';
import RestaurantPosStatus from '@/components/buttons/RestaurantPosStatus.vue';
import SynchronizeBtn from '@/components/buttons/SynchronizeBtn.vue';
import RestaurantStatusSwitch from './RestaurantStatusSwitch.vue';
import TimeIcon from './TimeIcon.vue';

interface RequiredItem {
  id: string;
  name: string;
  status: number;
  createdAt: Date;
}

@Component({
  components: {
    RestaurantStatus,
    RestaurantPosStatus,
    SynchronizeBtn,
    RestaurantStatusSwitch,
    TimeIcon,
  },
})
export default class ConceptStatus extends Vue {
  @Prop({ required: true }) readonly concept!: ConceptModel;

  loadingRequiredItems = false;
  requiredItems: RequiredItem[] = [];
  isOpen = false; // Collapse state

  get turnedOffUntil(): string | null {
    if (!this.concept.storeStatus) {
      return null;
    }

    const until = Object.values(this.concept.storeStatus)?.[0]?.until;
    return until ? toLocale(until) : null;
  }

  async fetchRequiredItems() {
    try {
      this.loadingRequiredItems = true;
      const service = new ApiService();
      const response = await service.get(`concepts/required/items/${this.concept.id}`);
      this.requiredItems = response as any;
    } catch (error) {
      console.error('Failed to fetch required items:', error);
      this.requiredItems = [];
    } finally {
      this.loadingRequiredItems = false;
    }
  }

  get totalStatusZero(): number {
    return this.requiredItems.filter((item) => item.status === 0).length;
  }

  toggleCollapse() {
    this.isOpen = !this.isOpen;
  }

  mounted() {
    this.fetchRequiredItems();
  }
}
